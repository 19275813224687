import React from 'react'
import MainLayout from '../layouts/MainLayout'
import { Card, Button, CardTitle, CardText, Row, Col, CardImg, CardBody } from 'reactstrap';
import partnerImage from 'images/partner-image2.jpg'
import customerImage from 'images/customer-image.jpg'
import "components/layout.scss"


const IndexPage = () => (
  <MainLayout>
    <div className="indexContent">
      <Row>
        <Col sm="6">
          <Card className="card-style">
          <CardImg top width="100%" src={customerImage} alt="Card image cap" />
          <CardBody>
            <CardTitle><h3>Kindful Customers</h3></CardTitle>
            <CardText>You desire to build an integration/custom application for the sole use of your organization. </CardText>
            <Button block className="indexButton" href="./customer">Customer API Docs</Button>
          </CardBody>
          </Card>
        </Col>
        <Col sm="6">
        <Card className="card-style">
          <CardImg top width="100%" src={partnerImage} alt="Card image cap" />
          <CardBody>
            <CardTitle><h3>Kindful Partners</h3></CardTitle>
            <CardText>You desire to build an integration that will be available in the App Directory for all Kindful Customers.</CardText>
            <Button block className="indexButton" href="./partner">Partner API Docs</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </MainLayout>
)
export default IndexPage